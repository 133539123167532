<template>
  <div class="home">
    <Chicklets/>
  </div>
</template>

<script>
  import Chicklets from '@/components/Chicklets'

  export default {
    name: 'home',
    components: {
      Chicklets
    }
  }
</script>
